import './App.css';
import Header from './Header';
import Biz from './Biz';
import avatar from './avatar.svg'


function App() {
  return (
    <div >
      <header className='header'>
        <Header/>
      </header>
      {/* Malumot */}
      <div className='malumotlar'>
<p className='asosiy'>Asosiy / Kasplar / Shifokor</p>
      </div>
   {/* asida */}
 <aside>
  <div className='olo'>
<div className='ui'>
<div><a href='#' className='me'>Mening profilim </a></div>
<div><a href='#' className='me'>Test natijalari</a></div>
<div><a href='#' className='me'>Treninglar</a></div>
<div><a href='#' className='me'>Mening rezume</a></div>
<div><a href='#' className='me1'>Chiqish</a></div>
</div>
  </div>
  <div className='umu'>
<p className='rim'>Shaxsiy ma’lumotlarim</p>
<div className='inp'>
  <div><p>Ismingiz</p><input placeholder='Yozing' className='t' type="text" /></div>
  <div><p>Familya</p><input placeholder='Yozing' className='t' type="text" /></div>
  <div><p>Viloyat</p><input placeholder='Yozing' className='t' type="text" /></div>
  <div><p>Tuman</p><input placeholder='Yozing' className='t' type="text" /></div>
</div>
<div className='maa'>
    <div><p>Maktab #</p> <input placeholder='Yozing' className='q' type="text" /></div>
    <div><p>Sinif </p><input placeholder='Yozing' className='q' type="text" /></div>
    <div><p>Harf_ A,B..</p> <input placeholder='Yozing' className='q' type="text" /></div>
  </div>
  <p className='emil'>Email</p>
  <input placeholder='Yozing' className='xy' type="text" />
  <div className='sss'>
<a className='saq' href="#">Saqlash</a>
  </div>
  <div className='parl'>
<p className='anii'>Parolni yangilash</p>
<div className='ddd'>
    <div><p>Yangi parol</p> <input type="text" className='cl' placeholder='Yozing'/></div>
    <div><p>Eski parol</p> <input type="text" className='cl' placeholder='Yozing'/></div>
  </div>
  <div className='rtyu'>
<a className='gfds' href="#">Saqlash</a>
  </div>
  
  </div>
  <img className='avar' src={avatar} alt="" />
  </div>
  
 </aside>
{/* Bizning joyi */}
 <Biz />

    </div>
  );
}

export default App;